import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

window.sliders = [];

window.addEventListener('load', (event) => {
    const btns = document.querySelectorAll('img.hover-replace');
    for (let i = 0; i < btns.length; i++) {
        if (undefined !== btns[i].src) {
            btns[i].addEventListener('mouseover', function(e) {
                e.currentTarget.src = e.currentTarget.src.replace('_off','_on');
            });
            btns[i].addEventListener('mouseout', function(e) {
                e.currentTarget.src = e.currentTarget.src.replace('_on','_off');
            });
        }
    }

    const sliders = document.querySelectorAll('.slider');
    for (let i = 0; i < sliders.length; i++) {
        let id = sliders[i].getAttribute('id');
        if (!id) {
            id = 'slider-' + i;
            sliders[i].setAttribute('id', id);
        }
        sliders[i].classList.add('swiper');
        const next = document.createElement("div");
        next.classList.add('swiper-button-next');
        sliders[i].appendChild(next);
        const prev = document.createElement("div");
        prev.classList.add('swiper-button-prev');
        sliders[i].appendChild(prev);
        const pagination = document.createElement("div");
        pagination.classList.add('swiper-pagination');
        sliders[i].appendChild(pagination);

        const slides = sliders[i].querySelectorAll('.swiper-slide');
        if (slides) {
            console.log(slides.length);
        }

        window.sliders[i] = new Swiper('#' + id, {
            modules: [Navigation, Pagination],
            loop: true,
            speed: 800,
            slidesPerView: 1,
            centeredSlides : false,
            watchOverflow: true,
            pagination: {
                el: '#' + id + ' .swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '#' + id + ' .swiper-button-next',
                prevEl: '#' + id + ' .swiper-button-prev',
            },
            breakpoints: {
                700: {
                    width: 700,
                    slidesPerView: 1,
                }
            },
        });
    }

    const hostname = location.hostname;
    const elements = document.getElementsByTagName('a');
    for(let element of elements){
        let href = element.getAttribute('href');
        if(href.startsWith('http') && href.indexOf(hostname) === -1) {
            //a要素にtarget="_blank"を追加する
            element.setAttribute('target', '_blank');
            //a要素にrel="noopener noreferrer"を追加する
            element.setAttribute('rel', 'noopener noreferrer');
        }
    }
});